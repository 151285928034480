import type {
  WidgetManifest,
  GfppDesktopConfig,
} from '@wix/platform-editor-sdk';
import classicPreset from '../assets/blocks-post-page/classic-preset.png';
import overlayPreset from '../assets/blocks-post-page/overlay-preset.png';
import stackedPreset from '../assets/blocks-post-page/stacked-preset.png';
import { POST_PAGE_PRESETS } from '../components/Post Page/post-page-constants';
import { postPageWidgetDesign } from './blocks-post-page-manifest-widget-design';

export const buildBlocksPostPageManifest = (
  dataFromBlocks: WidgetManifest,
): WidgetManifest => ({
  ...dataFromBlocks,
  default: {
    ...dataFromBlocks.default,
    behavior: {
      removable: false,
      duplicatable: false,
      toggleShowOnAllPagesEnabled: false,
      essential: {
        enabled: false,
      },
    },
    gfpp: {
      ...dataFromBlocks.default.gfpp,
      desktop: {
        ...(dataFromBlocks.default.gfpp?.desktop ?? {}),
        iconButtons: (dataFromBlocks.default.gfpp?.desktop as GfppDesktopConfig)
          ?.iconButtons,
        widgetDesign: postPageWidgetDesign,
        widgetPresets: {
          presets: [
            {
              id: POST_PAGE_PRESETS.DESKTOP_CLASSIC_LEFT,
              src: classicPreset,
            },
            {
              id: POST_PAGE_PRESETS.DESKTOP_OVERLAY_LEFT,
              src: overlayPreset,
            },
            {
              id: POST_PAGE_PRESETS.DESKTOP_STACKED_LEFT,
              src: stackedPreset,
            },
          ],
        },
      },
      mobile: {
        ...(dataFromBlocks.default.gfpp?.mobile ?? {}),
        // @ts-expect-error
        widgetPresets: {
          presets: [
            {
              id: POST_PAGE_PRESETS.MOBILE_CLASSIC_LEFT,
              src: classicPreset,
            },
            {
              id: POST_PAGE_PRESETS.MOBILE_OVERLAY_LEFT,
              src: overlayPreset,
            },
            {
              id: POST_PAGE_PRESETS.MOBILE_STACKED_LEFT,
              src: stackedPreset,
            },
          ],
        },
      },
    },
  },
});
