export const POST_PAGE_SELECTORS = {
  POST_HEADER: 'postHeader1',
  POST_CONTENT: 'postContent1',
  POST_FOOTER: 'postFooter1',
} as const;

export const POST_PAGE_PRESETS = {
  DESKTOP_CLASSIC_LEFT: 'variants-ln2sz6bn1',
  MOBILE_CLASSIC_LEFT: 'variants-lnirkhtr',
  DESKTOP_OVERLAY_LEFT: 'variants-lniv6gp0',
  MOBILE_OVERLAY_LEFT: 'variants-lnivui2q',
  DESKTOP_STACKED_LEFT: 'variants-lniw545p',
  MOBILE_STACKED_LEFT: 'variants-lniwdp8d',
} as const;
