import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { blogAppDefId } from '../../constants/apps';
import { TOKEN } from './constants';

type ComponentWithMetadata = {
  type: string;
  componentRef: ComponentRef;
  containerRef: ComponentRef;
  pageRef: ComponentRef;
};

export const getAllComponents = async (
  sdk: EditorSDK,
): Promise<ComponentWithMetadata[]> => {
  const allComponents = await sdk.components.getAllComponents(TOKEN);
  return Promise.all(
    allComponents.map((componentRef) =>
      Promise.all([
        sdk.components.getType(TOKEN, { componentRef }),
        sdk.components.getAncestors(TOKEN, { componentRef }),
      ]).then(
        ([type, ancestors]): ComponentWithMetadata => ({
          type,
          componentRef,
          containerRef: ancestors[0],
          pageRef: ancestors[ancestors.length - 1],
        }),
      ),
    ),
  );
};

interface CallbackParams {
  type: string;
  componentRef: ComponentRef;
  containerRef: ComponentRef;
  pageRef: ComponentRef;
}

type GetSerializedComponentsParams = {
  sdk: EditorSDK;
  predicate?: (params: CallbackParams) => boolean;
  maintainIdentifiers?: boolean;
};

export const getSerializedComponents = async ({
  sdk,
  predicate = () => true,
  maintainIdentifiers = true,
}: GetSerializedComponentsParams) => {
  const components = (await getAllComponents(sdk)).filter(predicate);

  const serializedComponents = await Promise.all(
    components.map(({ componentRef, type, ...rest }) =>
      sdk.document.components
        .serialize(TOKEN, { componentRef, maintainIdentifiers })
        .then((serialized) => ({ serialized, componentRef, ...rest }))
        .catch((err) => {
          if (type.indexOf('AppPart2') > -1) {
            return;
          }

          throw err;
        }),
    ),
  );

  return serializedComponents.filter(Boolean);
};

const getUniqueSectionId = (section: string) => async (sdk: EditorSDK) => {
  for (let i = 0; i < 10; i++) {
    const sectionId = `${section}_${getUniqueId('', '')}`;
    const isSectionIdTaken = Boolean(
      await sdk.document.components.getById(TOKEN, { id: sectionId }),
    );
    if (!isSectionIdTaken) {
      return sectionId;
    }
  }

  throw new Error('cannot generate an unique section id');
};

export const getUniqueSectionIdForFeedPage = getUniqueSectionId('TPASection');
export const getUniqueSectionIdForPostPage =
  getUniqueSectionId('TPAMultiSection');

export const getComponentRef = async (sdk: EditorSDK, widgetId: string) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId(TOKEN, blogAppDefId);
  if (!blogAppData) {
    return;
  }

  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(
    TOKEN,
    blogAppData.applicationId,
  );
  if (!blogAppComponents) {
    return;
  }

  const component = blogAppComponents.find((c) => c.widgetId === widgetId);

  if (!component) {
    return;
  }

  const componentRef = await sdk.document.components.getById(TOKEN, {
    id: component.id,
  });

  return componentRef;
};

export const getCoordinatesRelativeToStructure = async (
  sdk: EditorSDK,
  componentRef: ComponentRef,
) => {
  const ancestors = await sdk.components.getAncestors(TOKEN, { componentRef });
  const layouts = await Promise.all(
    [componentRef, ...ancestors].map((ancestor) =>
      sdk.components.layout.get(TOKEN, { componentRef: ancestor }),
    ),
  );
  const coordinates = layouts.reduce(
    (result, { x, y }) => {
      result.x += x;
      result.y += y;
      return result;
    },
    { x: 0, y: 0 },
  );

  return coordinates;
};

export const getIndexRef = async (
  sdk: EditorSDK,
  componentRef: ComponentRef,
) => {
  const ancestors = await sdk.components.getAncestors(TOKEN, { componentRef });
  const isNested = ancestors.length > 1;
  return isNested ? ancestors[ancestors.length - 2] : componentRef;
};

let lastGeneratedId: number | undefined;
let counter = 0;

function getUniqueId(prefix: string, prefixDelimiter: string): string {
  prefix = prefix || '';
  prefixDelimiter = prefixDelimiter || '';
  const value = Date.now();
  if (value === lastGeneratedId) {
    counter++;
  } else {
    lastGeneratedId = value;
    counter = 0;
  }
  return (
    prefix +
    prefixDelimiter +
    Number(lastGeneratedId).toString(36) +
    (counter ? counter : '')
  );
}
