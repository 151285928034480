import { get } from 'lodash';
import { isLayoutProGallery } from '@wix/communities-blog-client-common';
import { EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';
import {
  getStyleProperty,
  HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL,
} from '../constants/style-params';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import bi from './bi';
import concurrentEditing from './concurrent-editing';
import maIntegrationKit from './ma-integration-kit';
import { getComponentRef } from './magic-migration/sdk-utils';
import mapStyleParamsToPG from './map-pg-layout-styles';
import { openModalPanel } from './modal';
import monitoring from './monitoring';
import page from './page';
import retry from './retry';

export const initProvision = async ({ sdk }: EditorAppContext) => {
  try {
    await monitoring.toMonitored(
      'sdk.document.save',
      retry(sdk.document.save, 5, 100),
      true,
    );
    return true;
  } catch (_) {
    return false;
  }
};

export const initBiService = async (context: EditorAppContext) => {
  await bi.init(context);
};

const showProvisioningModal = async (context: EditorAppContext) => {
  const { sdk, appToken, biData } = context;
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);

  const modalResult = await openModalPanel(context, {
    // url: 'https://localhost:33999/assets/modal.html', // use local communities-blog-provisioning-modal statics
    url: get(appData, 'appFields.platform.baseUrls.provisioningModalUrl', ''),
    width: 744,
    height: 492,
    shouldHideHeader: true,
  });

  if (!modalResult) {
    return;
  }

  const { action, value } = modalResult;
  const membersAreaModalActions = {
    WRITING_TEAM: 'writing_team',
    MEMBERS_AREA: 'members_area',
  };
  const shouldInstallMembersArea =
    Object.values(membersAreaModalActions).includes(action) && value;

  console.log({ action, shouldInstallMembersArea });

  bi.provisioningModalClosed(action, shouldInstallMembersArea);
  if (shouldInstallMembersArea) {
    await concurrentEditing.withApproval(async () => {
      const canInstallWriterPage =
        await maIntegrationKit.canInstallBlogWriterProfilesOnly(sdk as any);
      const blogWriterProfilesOnly =
        membersAreaModalActions.WRITING_TEAM === action && canInstallWriterPage;

      if (blogWriterProfilesOnly) {
        await maIntegrationKit.registerMembersAreaApps(
          [],
          [
            {
              id: maIntegrationKit.MA_APP_IDS.FOLLOWERS,
              options: { shouldInstallInitially: false },
            },
            {
              id: maIntegrationKit.MA_APP_IDS.BLOG_POSTS,
              options: { shouldInstallInitially: false },
            },
            {
              id: maIntegrationKit.MA_APP_IDS.BLOG_COMMENTS,
              options: { shouldInstallInitially: false },
            },
            {
              id: maIntegrationKit.MA_APP_IDS.MY_DRAFTS,
              options: { shouldInstallInitially: false },
            },
            {
              id: maIntegrationKit.MA_APP_IDS.MY_POSTS,
              options: { shouldInstallInitially: false },
            },
            {
              id: maIntegrationKit.MA_APP_IDS.BLOG_LIKES,
              options: { shouldInstallInitially: false },
            },
          ],
        );
      }

      await maIntegrationKit.installMembersArea({
        blogWriterProfilesOnly,
        biData,
      });
    });
  }
};

export const displayProvisioningModal = async (context: EditorAppContext) => {
  try {
    bi.provisioningModalDisplayed();
    await monitoring.toMonitored(
      'provisioning-modal',
      showProvisioningModal(context),
    );
  } catch (error) {
    console.warn(error);
  }
};

export const setStyleParams = async ({
  sdk,
  appToken,
  isADI,
  isClassicEditor,
}: EditorAppContext) => {
  if (isADI) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(
    appToken,
    blogAppDefId,
  );
  const blogAppComponents =
    await sdk.document.tpa.app.getAllCompsByApplicationId(
      appToken,
      blogAppData.applicationId,
    );
  if (!blogAppComponents) {
    return;
  }

  const blogComponent = blogAppComponents.find(
    (component) => component.widgetId === BLOG_WIDGET_ID,
  );
  if (!blogComponent) {
    return;
  }
  const blogComponentRef = await sdk.document.components.getById(appToken, {
    id: blogComponent.id,
  });
  const blogComponentStyle = await sdk.components.style.get(appToken, {
    componentRef: blogComponentRef,
  });

  const homepageLayoutType = getStyleProperty(
    blogComponentStyle,
    HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL,
  );

  if (isLayoutProGallery(homepageLayoutType)) {
    return;
  }

  const styleParams = mapStyleParamsToPG(blogComponentStyle, isClassicEditor);

  if (styleParams.length < 1) {
    return;
  }

  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: blogComponentRef,
    styleParams,
  });
};

export const openPostPageSettings = async (
  context: EditorAppContext,
  title?: string,
) => {
  const { sdk, appToken } = context;
  const widgetId = POST_WIDGET_ID;
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const instance = await sdk.document.info.getAppInstance(appToken);
  const componentRef = await getComponentRef(sdk, widgetId);

  if (!componentRef) {
    return;
  }

  const appSettingsUrl = appData.widgets[widgetId].settings.urlV2;
  const url = `${appSettingsUrl}?instance=${instance}&appDefinitionId=${blogAppDefId}&compId=${componentRef.id}`;

  const blogPage = await page.find({
    ...context,
    tpaPageId: TPA_PAGE_ID_POST,
  });

  await sdk.document.pages.navigateTo(appToken, {
    pageRef: blogPage as any,
  });

  sdk.editor.openComponentPanel(appToken, {
    url,
    title,
    type: sdk.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: appData.settingsHeight,
  });
};

export const openBlogPagesPanel = async ({
  sdk,
  appToken,
}: EditorAppContext) => {
  const { check, show } = sdk.editor.deeplink;
  const feature = {
    type: 'pagesPanel',
    params: [blogAppDefId] as string[],
  } as const;

  const deepLinkToBlogPagesPanel = await check(appToken, feature);

  if (deepLinkToBlogPagesPanel) {
    await show(appToken, feature);
  }
};
