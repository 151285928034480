import { get } from 'lodash';

export const getStyleProperty = (styles: any, property: string) =>
  get(styles, `style.properties.${property}`);

export const CATEGORY_LAYOUT_TYPE_STYLE_PARAM = 'layout-category-layoutType';
export const HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM = 'layout-homepage-layoutType';

export const AUTHOR_NAME_STYLE_ENABLED_PARAM = 'blog-isAuthorNameEnabled';
export const MORE_OPTIONS_STYLE_ENABLED_PARAM = 'blog-isMoreOptionsMenuEnabled';
export const POST_PUBLISH_DATE_ENABLED_STYLE_PARAM =
  'blog-isPostPublishDateEnabled';
export const IS_READING_TIME_ENABLED_PARAM = 'blog-isReadingTimeEnabled';
export const IS_VIEW_COUNT_ENABLED_PARAM = 'blog-isViewCountEnabled';
export const IS_LIKE_COUNT_ENABLED_STYLE_PARAM = 'blog-isLikeCountEnabled';
export const IS_COMMENT_COUNT_ENABLED_PARAM = 'blog-isCommentCountEnabled';
export const IS_POST_DESCRIPTION_ENABLED_PARAM =
  'blog-isPostDescriptionEnabled';

export const HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL =
  `param_number_${HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM}` as const;
export const CATEGORY_LAYOUT_TYPE_STYLE_PARAM_FULL =
  `param_number_${CATEGORY_LAYOUT_TYPE_STYLE_PARAM}` as const;

export const AUTHOR_NAME_STYLE_ENABLED_PARAM_FULL =
  `param_boolean_${AUTHOR_NAME_STYLE_ENABLED_PARAM}` as const;
export const MORE_OPTIONS_STYLE_ENABLED_PARAM_FULL =
  `param_boolean_${MORE_OPTIONS_STYLE_ENABLED_PARAM}` as const;
export const POST_PUBLISH_DATE_ENABLED_STYLE_PARAM_FULL =
  `param_boolean_${POST_PUBLISH_DATE_ENABLED_STYLE_PARAM}` as const;
export const IS_READING_TIME_ENABLED_PARAM_FULL =
  `param_boolean_${IS_READING_TIME_ENABLED_PARAM}` as const;
export const IS_VIEW_COUNT_ENABLED_PARAM_FULL =
  `param_boolean_${IS_VIEW_COUNT_ENABLED_PARAM}` as const;
export const IS_LIKE_COUNT_ENABLED_STYLE_PARAM_FULL =
  `param_boolean_${IS_LIKE_COUNT_ENABLED_STYLE_PARAM}` as const;
export const IS_COMMENT_COUNT_ENABLED_PARAM_FULL =
  `param_boolean_${IS_COMMENT_COUNT_ENABLED_PARAM}` as const;
export const IS_POST_DESCRIPTION_ENABLED_PARAM_FULL =
  `param_boolean_${IS_POST_DESCRIPTION_ENABLED_PARAM}` as const;
